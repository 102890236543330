import type { ReactNode } from "react"

interface Props {
  children?: ReactNode
}

export function DesktopOnly({ children }: Props) {
  return (
    <>
      <div className="hidden lg:block">{children}</div>
      <div className="mt-40 flex h-screen flex-col items-center gap-y-2 px-10 text-text-tertiary lg:hidden">
        <img src="/laptop.svg" alt="" />
        <h1 className="text-center">Доступно только на компьютере</h1>
      </div>
    </>
  )
}
