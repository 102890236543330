import { Link, isRouteErrorResponse, useRouteError } from "@remix-run/react"
import { captureRemixErrorBoundaryError } from "@sentry/remix"
import { Button } from "@/shared/ui/button"

const COMMON_STATUSES: Record<number, string> = {
  400: "Неверный запрос",
  403: "Доступ запрещён",
  404: "Страница не найдена",
  500: "Внутренняя ошибка",
}

export function ErrorBoundary() {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  return (
    <div className="bg-noisy flex h-screen w-screen flex-col items-center justify-center text-center">
      {isRouteErrorResponse(error) ? (
        <>
          <h1 className="text-marketing-large">{error.status}</h1>
          <h2 className="mt-4 text-marketing-large text-text-extraLight">
            {typeof error.data === "string" && error.data.length > 0
              ? error.data
              : (COMMON_STATUSES[error.status] ?? error.statusText)}
          </h2>

          <Button colorScheme="main" className="mt-16" asChild>
            <Link to="/" prefetch="viewport">
              На главную
            </Link>
          </Button>
        </>
      ) : error instanceof Error ? (
        error.message
      ) : (
        "Неизвестная ошибка"
      )}
    </div>
  )
}
